import { IRouteItem } from '../router.d';

export interface IRouteList {
  react: IRouteItem;
}

const link = '';

const Route: IRouteList = {
  react: {
    link: `${link}`,
    name: 'Home',
  },
};

export default Route;
