import MainLayout from '@layouts/MainLayout';
import React, { lazy } from 'react';
import RouterRender from '../routerRender';
import R from './Router';

const Router = (): any => [
  RouterRender([
    {
      link: R.react.link,
      layout: MainLayout,
      component: lazy(() => import('@pages/Home')),
    },
  ]),
].map((router: any) => router);

export default Router();

// (props): JSX.Element => (<>{props?.children}</>)