import React, { ReactElement, Suspense } from 'react';
import './styles/globals.less';
import { BrowserRouter, Switch } from 'react-router-dom';
import { Button } from 'antd';
import StateContext from '@context/StateContext';
import { Page } from '@stories/Page';
import HomeRouter from '@routers/HomeRouter';

function SuspenseContent(): JSX.Element {
  return (
    <>SuspenseContent</>
  );
}

function App(): ReactElement {
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<SuspenseContent />}>
          <StateContext.Provider value={{}}>
            <Switch>
              {HomeRouter}
            </Switch>
          </StateContext.Provider>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
