import React from "react";


export interface IStateContext {
  
}

const StateContext = React.createContext<IStateContext>({

});

export default StateContext;