import { Avatar, Button, Col, Image, Row, Space } from 'antd'
import Layout, { Content, Header } from 'antd/lib/layout/layout'
import React from 'react'
import logo from '../image/logo.png'
import DustBg from '../image/DustBg.svg'
import Subtract from '../image/Subtract.svg'
import logoWh from '../image/logoWh.png'
import { FacebookFilled, GithubOutlined, MediumOutlined, MediumWorkmarkOutlined } from '@ant-design/icons'
import AutoScale from 'react-auto-scale';

const MainLayout: React.FC = (props): JSX.Element => {
  const { children } = props

  return (
    <div>
      <div style={{ margin: "auto", minWidth: "1440px", maxWidth: "1440px", position: "relative" }}>
        <span className='dust-bg' style={{ backgroundImage: `url(${DustBg})` }} />
        <Row className='w-100' justify="start">
          <Col offset={2} span={20}>
            <Header className='bg-color-trans' style={{ paddingTop: 51, paddingBottom: 47 }}>
              <Row align="middle" justify="space-between">
                <Image className='img-logo' src={logo} alt="" />
                <Row justify="end">
                  <Button type="link" className='btn-head f-wh'>
                    เกี่ยวกับเรา
                  </Button>
                  <Button type="link" className='btn-head f-wh'>
                    ติดต่อเรา
                  </Button>
                  <Button type="link" className='btn-head f-wh'>
                    ร่วมงานกับเรา
                  </Button>
                </Row>
              </Row>
            </Header>
          </Col>
          <Col span={24}>
            <div>{children}</div>
          </Col>
          <Col className='footer' span={24}>
            <Row justify='center'>
              <Space className="component" direction='vertical'>
                <Row justify='center' className='mt-5'>
                  <img src={logoWh} alt="" />
                </Row>
                <Row justify='center' className='mt-3'>
                  <Space direction='horizontal' size={20}>
                    <Button type="link" className='f-wh' >
                      <FacebookFilled style={{ fontSize: 30 }} />
                    </Button>
                    <Button type="link" className='f-wh' >
                      <MediumOutlined style={{ fontSize: 30 }} />
                    </Button>
                    <Button type="link" className='f-wh' >
                      <GithubOutlined style={{ fontSize: 30 }} />
                    </Button>
                  </Space>
                </Row>
                <p style={{ opacity: ".7" }} className="mt-3">Copyright ©2022 Produced by Trend Horizon Co., Ltd.</p>
              </Space>
              <div className='image' style={{ backgroundImage: `url(${Subtract})` }} />
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default MainLayout